import {
  LineAdd,
  LineArrowForward2,
  LineSearch,
} from "@autoprom/autoprom-icons";
import { Input, Menu, Text } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { PageLoader } from "../../components/page-loader";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import style from "./index.module.css";

const vehicleTypes: Record<string, { value: string; label: string }> = {
  car: {
    value: "car",
    label: "Cars",
  },
  motorcycle: {
    value: "motorcycle",
    label: "Motorcycle",
  },
};

export const Brand = () => {
  const { brand: brandParam, vehicleType } = useParams<{
    brand: string;
    vehicleType: string;
  }>();
  const [brand, setBrand] = useState<{
    logo: string;
    name: string;
    slug: string;
    vehicleType: string;
    models: Array<{
      value: string;
      label: string;
    }>;
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [filteredModels, setFilteredModels] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >([]);

  useEffect(() => {
    if (!brand) {
      setFilteredModels([]);

      return;
    }

    if (!search) {
      setFilteredModels(
        brand.models.sort((a, b) => {
          if (a.value === "other" || b.value === "other") {
            return 1;
          }

          if (a.label < b.label) {
            return -1;
          }

          return 1;
        })
      );

      return;
    }

    setFilteredModels(() => {
      return brand.models
        .filter((model) => {
          const foundName = model.label.search(new RegExp(search, "i"));

          return foundName >= 0;
        })
        .sort((a, b) => {
          if (a.value === "other" || b.value === "other") {
            return -1;
          }

          if (a.label < b.label) {
            return 1;
          }

          return -1;
        });
    });
  }, [brand, search]);

  useEffect(() => {
    const fetchBrand = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/brands/${vehicleType}/${brandParam}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setBrand({
        name: result.data.name,
        slug: result.data.slug,
        vehicleType: result.data.vehicleType,
        logo: getImagekitImages({
          src: result.data.logo.original,
          width: "180",
        }),
        models: result.data.models.map((model: any) => ({
          value: model.slug,
          label: model.name,
        })),
      });

      setIsLoading(false);
    };

    fetchBrand();
  }, [brandParam, vehicleType, userToken]);

  const history = useHistory();

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onEditBrand = () => {
    history.push(`/${vehicleType}/brands/${brand?.slug}/edit`);
  };

  const onPressModel = (modelValue: string) => () => {
    history.push(`/${vehicleType}/brands/${brand?.slug}/models/` + modelValue);
  };

  const onPressAddModel = () => {
    history.push(`/${vehicleType}/brands/${brand?.slug}/add-model`);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      header={
        <Page.Header onEdit={onEditBrand} backUrl={`/${vehicleType}/brands`} />
      }
      title={brand?.name}
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType]?.label,
          value: "/" + vehicleType,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
      ]}
      image={brand?.logo}
    >
      <div className={style["models__title"]}>
        <Text variant="title2" as="span">
          Models
        </Text>
        <div className={style["models__add"]} onClick={onPressAddModel}>
          <LineAdd />
        </div>
      </div>
      <div className={style["search-bar"]}>
        <Input
          placeholder="Search model..."
          prefixIcon={LineSearch}
          value={search}
          onChange={onChangeSearch}
        />
      </div>
      <div className={style["list"]}>
        {filteredModels.map((model) => {
          return (
            <Menu
              suffixIcon={LineArrowForward2}
              onClick={onPressModel(model.value)}
            >
              {model.label}
            </Menu>
          );
        })}
      </div>
    </Page>
  );
};
