import { getPath } from "../../../../../services/api/getPath";
import { toFormData } from "../../../../../utils/toFormData";

export const editBrand = async (
  props: {
    name: string;
    description: string;
    logo?: File;
  },
  options: {
    slug: string;
    vehicleType: string;
    userToken: string;
  }
) => {
  const { ...rest } = props;
  const formData = toFormData(rest);

  return new Promise<{
    e: ProgressEvent<XMLHttpRequestEventTarget>;
    request: XMLHttpRequest;
  }>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open(
      "PATCH",
      getPath(`/admin/brands/${options.vehicleType}/${options.slug}`)
    );

    request.setRequestHeader("x-authorization", "Bearer " + options.userToken);

    request.addEventListener("load", (e) => {
      resolve({
        request,
        e,
      });
    });

    request.addEventListener("error", (e) => {
      reject(e);
    });

    request.send(formData);
  });
};
