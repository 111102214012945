export const getVehicleTypes = (): Record<
  string,
  {
    label: string;
    value: string;
  }
> => {
  return {
    car: {
      value: "car",
      label: "Car",
    },
    motorcycle: {
      value: "motorcycle",
      label: "Motorcycle",
    },
  };
};
