import React, { useEffect } from "react";
import { store, useStoreState } from "../../services/store";
import { Login } from "../login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Dashboard } from "../dashboard";
import { VideoReviews } from "../video-reviews";
import { Listings } from "../listings";
import { Listing } from "../listing";
import { Brands } from "../brands";
import { Brand } from "../brand";
import { Model } from "../model";
import { Trims } from "../trims";
import { Trim } from "../trim";
import { AddBrand } from "../brands/components/AddBrand";
import { EditBrand } from "../brand/components/EditBrand";
import { AddModel } from "../brand/components/AddModel";
import { EditModel } from "../model/components/EditModel";
import { Car } from "../car";
import { Motorcycle } from "../motorcycle";
import { AddTrim } from "../trims/components/AddTrim";
import { EditTrim } from "../trim/components/EditTrim";
import { AddReview } from "../video-reviews/components/AddReview";
import { EditReview } from "../video-reviews/components/EditReview";
import { Leads } from "../leads";
import { Lead } from "../lead";
import { LeadContact } from "../lead-contact";
import { AddLead } from "../AddLead";
import { AddContact } from "../AddContact";

function App() {
  const user = useStoreState("user");
  useEffect(() => {
    let localUser;
    try {
      localUser = JSON.parse(
        localStorage.getItem("project-cars.admin.me") || ""
      );
    } catch {}
    if (localUser) {
      const decodedData = JSON.parse(
        window.atob(localUser.token.split(".")[1])
      );

      if (decodedData.exp * 1000 < new Date().getTime()) {
        return;
      }
      store.set("userToken", localUser.token);
      store.set("user", localUser.user);
    }
  }, []);

  if (!user) {
    return <Login />;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/listings/:slug">
          <Listing />
        </Route>
        <Route path="/edited-listings/:slug">
          <Listing isEdited />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/trims/add-trim">
          <AddTrim />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/trims/:trim/edit">
          <EditTrim />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/trims/:trim">
          <Trim />
        </Route>

        <Route path="/:vehicleType/brands/:brand/models/:model/video-reviews/add-review">
          <AddReview />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/video-reviews/:review">
          <EditReview />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/video-reviews">
          <VideoReviews />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/trims">
          <Trims />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model/edit">
          <EditModel />
        </Route>
        <Route path="/:vehicleType/brands/:brand/models/:model">
          <Model />
        </Route>
        <Route path="/:vehicleType/brands/:brand/add-model">
          <AddModel />
        </Route>
        <Route path="/:vehicleType/brands/:brand/edit">
          <EditBrand />
        </Route>
        <Route path="/:vehicleType/brands/add-brand">
          <AddBrand />
        </Route>
        <Route path="/:vehicleType/brands/:brand">
          <Brand />
        </Route>

        <Route path="/:vehicleType/brands">
          <Brands />
        </Route>
        <Route path="/:vehicleType/listings">
          <Listings />
        </Route>
        <Route path={"/motorcycle"}>
          <Motorcycle />
        </Route>
        <Route path={"/car"}>
          <Car />
        </Route>

        <Route path="/leads/:leadId/contact-history/:contactId">
          <LeadContact />
        </Route>
        <Route path="/leads/add">
          <AddLead />
        </Route>
        <Route path="/leads/:leadId/add-contact">
          <AddContact />
        </Route>
        <Route path="/leads/:leadId">
          <Lead />
        </Route>
        <Route path="/leads">
          <Leads />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
