import { IconLoader } from "@autoprom/autoprom-ui";
import style from "./style.module.css";

export const PageLoader = () => {
  return (
    <div className={style["page"]}>
      <IconLoader />
    </div>
  );
};
