import {
  LineAdd,
  LineArrowBack2,
  LineClose,
  LineEdit,
  SolidDelete,
} from "@autoprom/autoprom-icons";
import { cx, Text } from "@autoprom/autoprom-ui";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

export const Page: React.FunctionComponent<{
  title?: string;
  header?: JSX.Element;
  image?: string;
  breadcrumbs?: Array<{
    value: string;
    label: string;
  }>;
}> & {
  Header: typeof Header;
} = ({ title, children, header, image, breadcrumbs = [] }) => {
  return (
    <>
      {header}
      <div
        className={cx(
          style["page"],
          !!header && style["page--with-header"],
          !!breadcrumbs.length && style["page--with-breadcrumbs"]
        )}
      >
        {!!image && (
          <img className={style["page__image"]} src={image} alt={title} />
        )}

        {!!breadcrumbs.length && (
          <div className={style["page__breadcrumbs"]}>
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <>
                  <div key={index}>
                    <Text variant="label" color="black-80">
                      {breadcrumb.label}
                    </Text>
                  </div>
                  {index < breadcrumbs.length - 1 && (
                    <Text
                      variant="label"
                      color="black-80"
                      className={style["page__breadcrumb-divider"]}
                    >
                      {index < breadcrumbs.length - 1 ? "-" : ""}
                    </Text>
                  )}
                </>
              );
            })}
          </div>
        )}

        {!!title && (
          <Text variant="headline3" className={style["page__title"]}>
            {title}
          </Text>
        )}
        {children}
      </div>
    </>
  );
};

const Header: React.FC<{
  onAdd?(): void;
  onEdit?(): void;
  onDelete?(): void;
  onClose?(): void;
  backUrl?: string;
}> = ({ onEdit, onAdd, onDelete, onClose, backUrl }) => {
  const history = useHistory();
  const onBack = () => {
    if (!backUrl) {
      return;
    }
    history.push(backUrl);
  };

  return (
    <div className={style["page__header"]}>
      {onClose ? (
        <div className={style["page__action"]} onClick={onClose}>
          <LineClose />
        </div>
      ) : backUrl ? (
        <div className={style["page__action"]} onClick={onBack}>
          <LineArrowBack2 />
        </div>
      ) : null}
      <div className={style["page__actions"]}>
        {!!onEdit && (
          <div className={style["page__action"]} onClick={onEdit}>
            <LineEdit />
          </div>
        )}

        {!!onAdd && (
          <div className={style["page__action"]} onClick={onAdd}>
            <LineAdd />
          </div>
        )}

        {!!onDelete && (
          <div
            className={cx(style["page__action"], style["page__action--delete"])}
            onClick={onDelete}
          >
            <SolidDelete />
          </div>
        )}
      </div>
    </div>
  );
};

Page.Header = Header;
