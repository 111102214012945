import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { PageLoader } from "../../components/page-loader";
import { getPath } from "../../services/api/getPath";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../utils/getVehicleTypes";
import { Review, VideoReview } from "./components/video-review";
import style from "./index.module.css";
const vehicleTypes = getVehicleTypes();

export const VideoReviews = () => {
  const history = useHistory();
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
  }>();
  const [model, setModel] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [brand, setBrand] = useState<{
    label: string;
    value: string;
    logo?: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<Array<Review>>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const [brandResult, modelResult] = await Promise.all([
        fetch(
          getPath(`/vehicle-types/${vehicleType}/brands/${brandParam}`)
        ).then((r) => r.json()),
        fetch(
          getPath(
            `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}`
          )
        ).then((r) => r.json()),
      ]);

      if (brandResult.status === "ok" && modelResult.status === "ok") {
        setModel({
          label: modelResult.data.name,
          value: modelResult.data.slug,
        });
        setBrand({
          label: brandResult.data.name,
          value: brandResult.data.slug,
          logo: getImagekitImages({
            src: brandResult.data.logo.original,
            width: "180",
          }),
        });
        const videoReviewsResult = await fetch(
          getPath(
            `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
          )
        ).then((r) => r.json());

        if (videoReviewsResult.status === "ok") {
          setItems(videoReviewsResult.data);
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [modelParam, brandParam, vehicleType]);

  const onAddVideoReview = () => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews/add-review`
    );
  };

  const onPressEditReview = (reviewId: string) => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews/${reviewId}`
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      title="Video Reviews"
      header={
        <Page.Header
          onAdd={onAddVideoReview}
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}`}
        />
      }
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.label as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
      ]}
      image={brand?.logo}
    >
      <div className={style.list}>
        {items.map((item) => {
          return <VideoReview review={item} onPressEdit={onPressEditReview} />;
        })}
      </div>
    </Page>
  );
};
