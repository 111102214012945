import { useContext } from "react";
import { ListingContext } from "../../context";
import style from "./style.module.css";
import { Knob, Text } from "@autoprom/autoprom-ui";
import { LineLocationOn } from "@autoprom/autoprom-icons";

export const Location = () => {
  const context = useContext(ListingContext);

  if (!context.listing?.location) {
    return null;
  }

  const coordinates = context.listing.location.geo.coordinates;
  const locationText = context.listing.location.location;

  return (
    <div className={style.location}>
      <div className={style["subtitle"]}>
        <Text as="h2" variant="title2">
          Location
        </Text>
      </div>
      <a
        href={`https://maps.google.com/?q=${coordinates[0]},${coordinates[1]}`}
        target="_blank"
        className={style["location__text"]}
      >
        <Knob
          icon={LineLocationOn}
          variant="secondary"
          color="black-10"
          size="small"
        />
        <Text variant="label" color="black-50">
          {locationText}
        </Text>
      </a>
    </div>
  );
};
