import { useEffect, useRef, useState } from "react";
let YouTube: any;
export const YoutubePlayer: React.FunctionComponent<{
  videoId: string;
  onReady?(event: any): void;
}> = (props) => {
  const { videoId, onReady = () => null } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [youtube, setPlayer] = useState(YouTube);
  useEffect(() => {
    if (!youtube) {
      return;
    }

    if (!containerRef.current) {
      return;
    }

    const boundingBox = containerRef.current.getBoundingClientRect();

    new youtube.Player(containerRef.current, {
      height: boundingBox.height,
      width: boundingBox.width,
      videoId,
      playerVars: {
        enablejsapi: 1,
      },
      events: {
        onReady,
      },
    });
  }, [youtube, videoId, onReady]);

  useEffect(() => {
    if (YouTube) {
      return;
    }

    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/player_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    (window as any).onYouTubePlayerAPIReady = () => {
      YouTube = (window as any).YT;
      setPlayer(YouTube);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      ref={containerRef}
    ></div>
  );
};
