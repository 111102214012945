import { Text } from "@autoprom/autoprom-ui";
import { useContext } from "react";
import { ListingContext } from "../../context";
import style from "./style.module.css";
import { getDetailsAndEquipment } from "./utils/get-details-and-equipment";

const SpecItem: React.FunctionComponent<{
  label: string;
}> = ({ label, children }) => {
  return (
    <div className={style["specs__item"]}>
      <div>
        <Text color="black-50">{label}</Text>
      </div>
      {!!children && (
        <div>
          <Text>{children}</Text>
        </div>
      )}
    </div>
  );
};

export const Details = () => {
  const context = useContext(ListingContext);

  if (!context.listing) {
    return null;
  }

  const title = context.listing.texts.find(
    (item) => item.key === "title"
  )?.value;
  const description = context.listing.texts.find(
    (item) => item.key === "description"
  );

  const detailsAndEquipment = getDetailsAndEquipment(
    context.listing,
    context.settings
  );

  return (
    <div className={style.details}>
      <Text variant={"title1"} className={style.title}>
        {title}
      </Text>
      <div className={style["content__break"]} />
      <div className={style["subtitle"]}>
        <Text variant="title2" as="h2">
          Details
        </Text>
      </div>
      <div>
        {(
          detailsAndEquipment.details as Array<{
            label: string;
            value: string;
          }>
        ).map((detail) => (
          <SpecItem label={detail.label}>{detail.value}</SpecItem>
        ))}

        <div className={style["equipment__container"]}>
          {detailsAndEquipment.equipment.map((item) => (
            <SpecItem label={item} />
          ))}
        </div>
      </div>
      <div className={style["description"]}>
        <div className={style["subtitle"]}>
          <Text variant="title2" as="h2">
            Description
          </Text>
        </div>
        <Text color="black-50">{description?.value}</Text>
      </div>
    </div>
  );
};
