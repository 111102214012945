import { Page } from "../../../../components/page";
import { UploadLogo } from "../UploadLogo";
import { Button, Input, InputTextarea } from "@autoprom/autoprom-ui";
import style from "./index.module.css";
import { useEffect, useState } from "react";
import { addBrand } from "./utils/addBrand";
import { useHistory, useParams } from "react-router-dom";
import { useStoreState } from "../../../../services/store";
import validate from "validate.js";
import { getVehicleTypes } from "../../../../utils/getVehicleTypes";

const vehicleTypes = getVehicleTypes();

export const AddBrand = () => {
  const { vehicleType } = useParams<{ vehicleType: string }>();
  const history = useHistory();
  const authToken = useStoreState("userToken");
  const [formValues, setFormValues] = useState({
    name: "",
    slug: "",
    description: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    slug: "",
    description: "",
    logo: "",
  });
  const [logoImage, setLogoImage] = useState("");
  const [logo, setLogo] = useState<File>();

  useEffect(() => {
    if (logo) {
      setLogoImage(window.URL.createObjectURL(logo));

      return;
    }

    setLogoImage("");
  }, [logo]);

  const [isLoading, setIsLoading] = useState(false);

  const onPressSave = async () => {
    const newValidationErrors = validate(
      { ...formValues, logo },
      {
        name: {
          presence: {
            allowEmpty: false,
          },
        },
        slug: {
          presence: {
            allowEmpty: false,
          },
        },
        logo: {
          presence: {
            allowEmpty: false,
          },
        },
      }
    );

    if (newValidationErrors) {
      setValidationErrors(() => {
        const errors = Object.keys(validationErrors).reduce(
          (prev, key) => ({
            ...prev,
            [key]: newValidationErrors[key]?.[0] || "",
          }),
          {} as typeof validationErrors
        );

        return errors;
      });

      return;
    }

    setIsLoading(true);

    try {
      const result = await addBrand(
        {
          ...formValues,
          logo: logo as File,
        },
        {
          vehicleType,
          authToken,
        }
      );

      const response = JSON.parse(result.request.response);

      if (response.status === "error" && response.error.type === "generic") {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          name: response.error.message,
        }));

        setIsLoading(false);
        return;
      }

      history.replace(`/${vehicleType}/brands`);
    } catch {}
  };

  const onChangeInput =
    (input: keyof typeof formValues) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        [input]: e.target.value,
      }));
    };

  const onChangeLogo = (logo: File) => setLogo(logo);

  return (
    <Page
      title="Add new brand"
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: vehicleTypes[vehicleType].value,
        },
      ]}
      header={<Page.Header backUrl={`/${vehicleType}/brands`} />}
    >
      <UploadLogo
        value={logoImage}
        onChange={onChangeLogo}
        error={validationErrors.logo}
      />

      <div className={style["input"]}>
        <Input
          label="Brand name"
          value={formValues.name}
          error={validationErrors.name}
          onChange={onChangeInput("name")}
        />
      </div>

      <div className={style["input"]}>
        <Input
          label="Slug"
          onChange={onChangeInput("slug")}
          error={validationErrors.slug}
        />
      </div>
      <div className={style["input"]}>
        <InputTextarea
          onChange={onChangeInput("description")}
          label="SEO description"
          error={validationErrors.description}
          placeholder="Add description about this brand. It will be used to boost SEO search ability"
        />
      </div>
      <div className={style.button}>
        <Button onClick={onPressSave} isLoading={isLoading}>
          Save
        </Button>
      </div>
    </Page>
  );
};
