import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { PageLoader } from "../../components/page-loader";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import style from "./style.module.css";
import { Button, Checkbox, Text } from "@autoprom/autoprom-ui";
import { LineKeyboardArrowRight } from "@autoprom/autoprom-icons";

export const Lead = () => {
  const userToken = useStoreState("userToken");
  const history = useHistory();
  const [lead, setLead] = useState<{
    id: string;
    isSMSReceivePaused: boolean;
    fullName: string;
    phoneNumber: string;
    lastContacted: string;
    listingsCount: string;
    contactHistory: Array<{
      id: string;
      date: string;
      status: "sent" | "send-failed";
    }>;
  }>({
    id: "",
    fullName: "Unknown",
    phoneNumber: "",
    lastContacted: "",
    listingsCount: "",
    contactHistory: [],
    isSMSReceivePaused: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { leadId } = useParams<{ leadId: string }>();

  useEffect(() => {
    const fetchLead = async () => {
      setIsLoading(true);
      const response = await fetch(getPath(`/admin/leads/${leadId}`), {
        headers: {
          Accept: "application/json",
          "x-authorization": "Bearer " + userToken,
        },
      });

      const result = await response.json();

      setLead(result.data);
      setIsLoading(false);
    };

    fetchLead();
  }, [leadId, userToken]);

  if (isLoading) {
    return <PageLoader />;
  }

  const onPressContact = (id: string) => () => {
    history.push(`/leads/${leadId}/contact-history/${id}`);
  };

  const onEditLead = () => {
    history.push(`/leads/${leadId}/edit`);
  };

  const onChangeReceivingSMS = async (isReceivingSMS: boolean) => {
    setLead((prev) => ({
      ...prev,
      isSMSReceivePaused: !isReceivingSMS,
    }));

    try {
      await fetch(getPath(`/admin/leads/${leadId}/receiving-sms`), {
        method: "PATCH",
        body: JSON.stringify({
          isSMSReceivePaused: !isReceivingSMS,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer " + userToken,
        },
      });
    } catch (e) {}
  };

  const onPressSendWhatsApp = () => {
    history.push(`/leads/${leadId}/add-contact`);
  };

  return (
    <Page
      title={lead.fullName}
      header={<Page.Header backUrl="/leads" onEdit={onEditLead} />}
      breadcrumbs={[
        {
          value: "/leads",
          label: "Leads",
        },
      ]}
    >
      <div className={style["sms-toggle"]}>
        <Checkbox
          variant="toggle"
          isChecked={!lead.isSMSReceivePaused}
          onChange={onChangeReceivingSMS}
        >
          Receiving SMS
        </Checkbox>
      </div>
      <div className={style.details}>
        <div className={style.detail} key={"phone-number"}>
          <Text color="black-50" variant="body3">
            Phone number
          </Text>
          <Text variant="body3">{lead.phoneNumber}</Text>
        </div>
        <div className={style.detail} key={"last-contacted"}>
          <Text color="black-50" variant="body3">
            Last contacted
          </Text>
          <Text variant="body3">{lead.lastContacted}</Text>
        </div>
        <div className={style.detail} key={"listings-count"}>
          <Text color="black-50" variant="body3">
            Listings count
          </Text>
          <Text variant="body3">{lead.listingsCount}</Text>
        </div>
      </div>
      <Text variant="title2" className={style["contact-history-title"]}>
        Contact history
      </Text>
      <div className={style["contact-history-head"]}>
        <Text variant="body3" color="black-50">
          Date
        </Text>
        <Text variant="body3" color="black-50">
          Status
        </Text>
      </div>
      <div className={style["contact-history"]}>
        {lead.contactHistory.map((item) => (
          <div
            className={style["contact-history__item"]}
            onClick={onPressContact(item.id)}
          >
            <Text variant="body3">
              {item.status}
              {item.date}
            </Text>
            <Text
              variant="body3"
              color={item.status === "send-failed" ? "error" : "black"}
            >
              {item.status}
            </Text>
            <div className={style["history-action-arrow"]}>
              <LineKeyboardArrowRight />
            </div>
          </div>
        ))}
      </div>
      <div>
        <Button onClick={onPressSendWhatsApp}>Send WhatsApp</Button>
      </div>
    </Page>
  );
};
