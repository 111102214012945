export const getImagekitImages = ({
  src = "",
  width = "",
  height = null,
  isAutoCrop = false,
}) => {
  if (src.includes("https://") || src.includes("http://")) {
    return src;
  }
  const backslash = src[0] === "/" ? "" : "/";
  let transforms = [
    !!width && `w-${width}`,
    !!height && `h-${height}`,
    isAutoCrop && "fo-auto",
  ]
    .filter((v) => !!v)
    .join(",");
  if (transforms) {
    transforms = "tr=" + transforms;
  }

  const path = `${transforms ? "?" + transforms : ""}`;
  return `https://ik.imagekit.io/projectcars${backslash}${src}${path}`;
};
