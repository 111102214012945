import { Button, cx, Input } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../../../components/page";
import { PageLoader } from "../../../../components/page-loader";
import { YoutubePlayer } from "../../../../components/youtube-player";
import { getPath } from "../../../../services/api/getPath";
import { useStoreState } from "../../../../services/store";
import { getImagekitImages } from "../../../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../../../utils/getVehicleTypes";
import style from "./index.module.css";

const vehicleTypes = getVehicleTypes();

export const AddReview = () => {
  const authToken = useStoreState("userToken");
  const history = useHistory();
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
  }>();
  const [model, setModel] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [brand, setBrand] = useState<{
    label: string;
    value: string;
    logo?: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isVideoAdded, setIsVideoAdded] = useState(false);
  const [values, setValues] = useState({
    videoId: "",
    title: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const [brandResult, modelResult] = await Promise.all([
        fetch(
          getPath(`/vehicle-types/${vehicleType}/brands/${brandParam}`)
        ).then((r) => r.json()),
        fetch(
          getPath(
            `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}`
          )
        ).then((r) => r.json()),
      ]);

      if (brandResult.status === "ok" && modelResult.status === "ok") {
        setModel({
          label: modelResult.data.name,
          value: modelResult.data.slug,
        });
        setBrand({
          label: brandResult.data.name,
          value: brandResult.data.slug,
          logo: getImagekitImages({
            src: brandResult.data.logo.original,
            width: "180",
          }),
        });
      }

      setIsLoading(false);
    };

    fetchData();
  }, [modelParam, brandParam, vehicleType]);

  const onPressAdd = () => {
    setIsVideoAdded(true);
  };

  const onChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = e.target.value;
    if (!video) {
      setValues((oldVals) => ({ ...oldVals, videoId: "" }));
      return;
    }

    const videoIdMach =
      /.*youtube.com\/watch\?v=(.*)/gi.exec(video) ||
      /.*youtu.be\/(.*)/gi.exec(video);

    if (videoIdMach?.[1]) {
      setValues((oldVals) => ({ ...oldVals, videoId: videoIdMach?.[1] }));
      return;
    }

    setValues((oldVals) => ({ ...oldVals, videoId: "" }));
  };

  const onChangeValues = (key: keyof typeof values) => (value: string) => {
    setValues((oldVals) => ({ ...oldVals, [key]: value }));
  };

  const onPressSave = async () => {
    setIsSaving(true);

    const result = await fetch(
      getPath(
        `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
      ),
      {
        body: JSON.stringify(values),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer " + authToken,
        },
      }
    );

    if (result.status === 201) {
      history.push(
        `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
      );
    }
  };

  const onReadyYoutubePlayer = (e: any) => {
    onChangeValues("title")(e.target.getVideoData().title);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      title="Add video review"
      header={
        <Page.Header
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`}
        />
      }
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.label as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
        {
          label: "Video reviews",
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`,
        },
      ]}
    >
      {!isVideoAdded && (
        <div className={style.input}>
          <Input
            label="Youtube video"
            placeholder="ex. https://www.youtube.com/watch?v=xiOGiSA0EU8"
            onChange={onChangeVideo}
          ></Input>
          {!!values.videoId && (
            <div className={style.add}>
              <Button onClick={onPressAdd}>Add</Button>
            </div>
          )}
        </div>
      )}
      {isVideoAdded && (
        <>
          <div className={cx(style.input, style.player)}>
            <div className={style.video}>
              <YoutubePlayer
                videoId={values.videoId}
                onReady={onReadyYoutubePlayer}
              ></YoutubePlayer>
            </div>
          </div>
          <div className={style.input}>
            <Input
              label="Title"
              value={values.title || ""}
              onChange={(e) => onChangeValues("title")(e.target.value)}
            ></Input>
          </div>
          {!!values.title && (
            <div className={style["save-button"]}>
              <Button isFullWidth onClick={onPressSave} isLoading={isSaving}>
                Save
              </Button>
            </div>
          )}
        </>
      )}
    </Page>
  );
};
