import style from "./style.module.css";
import { Button, InputTextarea, Knob, Text } from "@autoprom/autoprom-ui";
import { LineClose } from "@autoprom/autoprom-icons";
import { useContext, useState } from "react";
import { getPath } from "../../../../services/api/getPath";
import { ListingContext } from "../../context";
import { useStoreState } from "../../../../services/store";
import { useHistory } from "react-router-dom";

type RejectModalProps = {
  isVisible: boolean;
  isEdited?: boolean;
  onClose(): void;
};

export const RejectModal: React.FunctionComponent<RejectModalProps> = (
  props
) => {
  const { isVisible, isEdited, onClose } = props;
  const history = useHistory();
  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const context = useContext(ListingContext);
  const authToken = useStoreState("userToken");
  if (!isVisible) {
    return null;
  }

  const onPressReject = async () => {
    setIsRejecting(true);

    try {
      await fetch(
        getPath(
          "/listings/" +
            (isEdited ? "edited/" : "") +
            context.listing?.id +
            "/reject"
        ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-authorization": "Bearer " + authToken,
          },
          body: JSON.stringify({
            rejectionReason,
          }),
        }
      );
      history.push(`/${context.listing?.vehicleType}/listings`);
    } catch {}
    setIsRejecting(false);
  };

  return (
    <div className={style.modal}>
      <div className={style.header}>
        <Text variant="title2">Reject listing</Text>
        <Knob icon={LineClose} variant="ghost" onClick={onClose} />
      </div>
      <div className={style.content}>
        <InputTextarea
          label="Rejection reason"
          placeholder="ex. Listing images violate image policy"
          onChange={(e) => {
            setRejectionReason(e.target.value);
          }}
        />
      </div>

      <div className={style.footer}>
        <Button
          onClick={onPressReject}
          isLoading={isRejecting}
          isDisabled={!rejectionReason}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};
