import { createContext } from "react";

export const ListingContext = createContext<{
  listing: {
    id: string;
    images: Array<{ original: string }>;
    texts: Array<{
      key: string;
      value: string;
    }>;
    location: any;
    author: any;
    contacts: any;
    vehicleType: string;
  } | null;
  settings: any;
}>({
  listing: null,
  settings: null,
});
