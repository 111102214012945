import { Menu, Text } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import style from "./style.module.css";

export const Dashboard = () => {
  const history = useHistory();
  const userToken = useStoreState("userToken");
  const [accountsCreated, setAccountsCreated] = useState(0);
  const [dailyAccounts, setDailyAccounts] = useState(0);
  const [activeListings, setActiveListings] = useState(0);
  const [dailyActiveListings, setDailyActiveListings] = useState(0);

  useEffect(() => {
    const fetchLeads = async () => {
      const response = await fetch(getPath(`/admin/stats`), {
        headers: {
          Accept: "application/json",
          "x-authorization": "Bearer " + userToken,
        },
      });

      const result = await response.json();

      setAccountsCreated(result.data.accountsCreated);
      setDailyAccounts(result.data.dailyAccountsCreated);
      setActiveListings(result.data.activeListings);
      setDailyActiveListings(result.data.dailyActiveListings);
    };

    fetchLeads();
  }, [userToken]);

  return (
    <div className={style["page"]}>
      <div className={style.logo}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 15.0943C0 7.97879 0 4.42103 2.21052 2.21052C4.42103 0 7.97879 0 15.0943 0H16.9057C24.0211 0 27.579 0 29.7894 2.21052C32 4.42103 32 7.97879 32 15.0943V16.9057C32 24.0211 32 27.579 29.7894 29.7894C27.579 32 24.0211 32 16.9057 32H15.0943C7.97879 32 4.42103 32 2.21052 29.7894C0 27.579 0 24.0211 0 16.9057V15.0943Z"
            fill="black"
          />
          <path
            d="M22.9434 22.9434H19.6611V21.9008C18.5982 22.5618 17.3437 22.9434 16 22.9434C12.1653 22.9434 9.05661 19.8347 9.05661 16C9.05661 12.1653 12.1653 9.05661 16 9.05661C17.3437 9.05661 18.5982 9.43827 19.6611 10.0991V9.05661H22.9434V22.9434Z"
            fill="#FDE3E9"
          />
        </svg>
      </div>
      <div className={style.stats}>
        <Text variant="title2">Stats</Text>
        <div className={style["stats__list"]}>
          <div className={style.stat}>
            <Text variant="title1">
              {accountsCreated}{" "}
              {!!dailyAccounts && <Text as="span">+{dailyAccounts}</Text>}
            </Text>
            <Text
              variant="label"
              className={style["stat__label"]}
              color="black-50"
            >
              Active accounts
            </Text>
          </div>
          <div className={style.stat}>
            <Text variant="title1">
              {activeListings}{" "}
              {!!dailyActiveListings && (
                <Text as="span">+{dailyActiveListings}</Text>
              )}
            </Text>
            <Text
              variant="label"
              className={style["stat__label"]}
              color="black-50"
            >
              Active listings
            </Text>
          </div>
        </div>
      </div>
      <div className={style["menu"]}>
        <Menu onClick={() => history.push("/car")}>Car</Menu>
        <Menu onClick={() => history.push("/motorcycle")}>Motorcycle</Menu>
        <Menu onClick={() => history.push("/leads")}>Leads</Menu>
        <Menu
          onClick={() =>
            window.open("https://logtail.com/team/65457/tail", "_blank")
          }
        >
          Logs
        </Menu>
      </div>
    </div>
  );
};
