import { LineArrowForward2, LineSearch } from "@autoprom/autoprom-icons";
import { IconLoader, Input, Menu } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../utils/getVehicleTypes";
import style from "./index.module.css";

const vehicleTypes = getVehicleTypes();

export const Brands = () => {
  const params = useParams<{
    vehicleType: string;
  }>();

  const history = useHistory();
  const userToken = useStoreState("userToken");
  const [brands, setBrands] = useState<
    Array<{
      logo: string;
      label: string;
      value: string;
      vehicleType: string;
    }>
  >([]);
  const [search, setSearch] = useState("");
  const [filteredBrands, setFilteredBrands] = useState<
    Array<{
      logo: string;
      label: string;
      value: string;
    }>
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!search && !params.vehicleType) {
      setFilteredBrands(brands);

      return;
    }

    setFilteredBrands(() => {
      return brands.filter((brand) => {
        const foundName = brand.label.search(new RegExp(search, "i"));

        let isFound = foundName >= 0;

        return isFound;
      });
    });
  }, [brands, search, params.vehicleType]);
  useEffect(() => {
    const fetchBrands = async () => {
      setIsLoading(true);

      const response = await fetch(
        getPath("/admin/brands/" + params.vehicleType),
        {
          headers: {
            "x-authorization": "Bearer " + userToken,
            Accept: "application/json",
          },
        }
      );

      const result = await response.json();

      setBrands(
        result.data.map((brand: any) => ({
          value: brand.slug,
          label: brand.name,
          vehicleType: brand.vehicleType,
          logo: getImagekitImages({
            src: brand.logo.original,
            width: "48",
          }),
        }))
      );
      setIsLoading(false);
    };

    fetchBrands();
  }, [userToken, params.vehicleType]);

  const onAddBrand = () => {
    history.push(`/${params.vehicleType}/brands/add-brand`);
  };

  const onPressBrand = (brandValue: string) => () => {
    history.push(`/${params.vehicleType}/brands/` + brandValue);
  };

  return (
    <Page
      title="Brands"
      breadcrumbs={[
        {
          label: vehicleTypes[params.vehicleType].label,
          value: vehicleTypes[params.vehicleType].value,
        },
      ]}
      header={
        <Page.Header onAdd={onAddBrand} backUrl={`/${params.vehicleType}`} />
      }
    >
      <div className={style["search-bar"]}>
        <Input
          placeholder="Search brand..."
          prefixIcon={LineSearch}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>

      <div className={style["brands"]}>
        {isLoading && (
          <div className={style.loader}>
            <IconLoader />
          </div>
        )}
        {filteredBrands.map((brand) => {
          return (
            <Menu
              prefix={
                <img
                  src={brand.logo}
                  alt={brand.label}
                  className={style["brand-logo"]}
                />
              }
              suffixIcon={LineArrowForward2}
              onClick={onPressBrand(brand.value)}
            >
              {brand.label}
            </Menu>
          );
        })}
      </div>
    </Page>
  );
};
