import { CardListing2, Pagination, Text } from "@autoprom/autoprom-ui";
import { useState } from "react";
import { useEffect } from "react";
import { FullsizePicture } from "react-responsive-picture";
import { getPath } from "../../../../services/api/getPath";
import { useStoreState } from "../../../../services/store";
import { toCardListing } from "../../utils/to-card-listing";
import style from "./style.module.css";

type ListingsProps = {
  vehicleType: string;
  listingType?: "unlisted" | "edited" | "verified";
  onPressListing(props: { slug: string; id: string }): void;
};

export const Listings: React.FunctionComponent<ListingsProps> = ({
  listingType = "unlisted",
  vehicleType,
  onPressListing,
}) => {
  const authToken = useStoreState("userToken");

  const [isLoading, setIsLoading] = useState(true);
  const [listings, setListings] = useState<
    Array<ReturnType<typeof toCardListing>>
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 20,
  });

  useEffect(() => {
    const fetchListings = async () => {
      setIsLoading(true);
      const listings = await fetch(
        getPath(
          `/listings/${listingType}?page=${currentPage}&vehicleType=${vehicleType}`
        ),
        {
          headers: {
            "Content-Type": "application/json",
            "x-authorization": "Bearer " + authToken,
          },
        }
      ).then((r) => r.json());
      setListings(listings.data.map((listing: any) => toCardListing(listing)));
      setPagination(listings.meta);
      setIsLoading(false);
    };

    fetchListings();
  }, [currentPage, vehicleType, authToken, listingType]);

  if (isLoading) {
    return (
      <div className={style["listings"]}>
        <CardListing2.Loader />
        <CardListing2.Loader />
        <CardListing2.Loader />
        <CardListing2.Loader />
      </div>
    );
  }

  const onChangePagination = (selectedPage: number) => {
    setCurrentPage(selectedPage + 1);
  };

  return (
    <>
      <div className={style["listings"]}>
        {listings.map((listing, index) => (
          <CardListing2
            key={index}
            onPress={() =>
              onPressListing({
                id: listing.id,
                slug: listing.slug,
              })
            }
            title={listing.title}
            description={<Text variant="body3">{listing.description}</Text>}
            specs={listing.specs}
          >
            <FullsizePicture src={listing.picture} />
          </CardListing2>
        ))}
      </div>
      {pagination.total > pagination.perPage && (
        <div className={style["pagination"]}>
          <Pagination
            isMinimal
            current={currentPage - 1}
            total={Math.round(pagination.total / pagination.perPage)}
            onChange={onChangePagination}
          ></Pagination>
        </div>
      )}
    </>
  );
};
