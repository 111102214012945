import { Text } from "@autoprom/autoprom-ui";
import { useRef } from "react";
import style from "./index.module.css";

export const UploadLogo: React.FC<{
  onChange(logo: File): void;
  value?: string;
  error?: string;
}> = (props) => {
  const { onChange, error, value } = props;
  const fileRef = useRef<HTMLInputElement>(null);

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = (e.target as HTMLInputElement).files || [];

    onChange(files[0]);
  };

  const onPressUploader = () => {
    if (fileRef.current) {
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      fileRef.current.dispatchEvent(clickEvent);
    }
  };

  return (
    <>
      <div className={style.container} onClick={onPressUploader}>
        {value ? (
          <img src={value} alt={"Brand logo"} />
        ) : (
          <Text variant="body2">Upload photo</Text>
        )}
      </div>
      {!!error && (
        <Text className={style.error} color="error" variant="label">
          {error}
        </Text>
      )}
      <input
        type="file"
        accept="image/x-png, image/png"
        ref={fileRef}
        className={style["input"]}
        onChange={_onChange}
      />
    </>
  );
};
