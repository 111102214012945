import { Page } from "../../components/page";
import style from "./index.module.css";
import { Text } from "@autoprom/autoprom-ui";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStoreState } from "../../services/store";
import { getPath } from "../../services/api/getPath";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../utils/getVehicleTypes";
import { PageLoader } from "../../components/page-loader";
import { DeleteModal } from "./components/DeleteModal";

const vehicleTypes = getVehicleTypes();
export const Trim = () => {
  const history = useHistory();
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
    trim: trimParam,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
    trim: string;
  }>();
  const [brand, setBrand] = useState<{
    logo: string;
    name: string;
    slug: string;
    vehicleType: string;
    models: Array<{
      value: string;
      label: string;
      trims: Array<{
        name: string;
        slug: string;
        description: string;
      }>;
    }>;
  }>();
  const [model, setModel] = useState<{
    value: string;
    label: string;
    trim: {
      name: string;
      description: string;
      slug: string;
    };
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    if (!brand) {
      return;
    }

    const foundModel = brand.models.find((m) => m.value === modelParam);
    const foundTrim = foundModel?.trims.find((t) => t.slug === trimParam);
    setModel({
      value: foundModel?.value,
      label: foundModel?.label,
      trim: foundTrim as {
        name: string;
        description: string;
        slug: string;
      },
    } as any);
  }, [brand, modelParam, trimParam]);

  useEffect(() => {
    const fetchBrand = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/brands/${vehicleType}/${brandParam}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setBrand({
        name: result.data.name,
        slug: result.data.slug,
        vehicleType: result.data.vehicleType,
        logo: getImagekitImages({
          src: result.data.logo.original,
          width: "180",
        }),
        models: result.data.models.map((model: any) => ({
          value: model.slug,
          label: model.name,
          trims: model.trims,
        })),
      });

      setIsLoading(false);
    };

    fetchBrand();
  }, [brandParam, userToken, vehicleType]);

  const onEditTrim = () => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims/${trimParam}/edit`
    );
  };
  const onDeleteTrim = () => {
    setIsDeleteModalVisible(true);
  };

  if (isDeleteModalVisible) {
    return (
      <DeleteModal
        brand={brandParam}
        model={modelParam}
        trim={trimParam}
        vehicleType={vehicleType}
        onDeleted={() => {
          history.replace(
            `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`
          );
        }}
        onClose={() => {
          setIsDeleteModalVisible(false);
        }}
      />
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      header={
        <Page.Header
          onEdit={onEditTrim}
          onDelete={onDeleteTrim}
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`}
        />
      }
      title={model?.trim.name}
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.name as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
        {
          label: "Trims",
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`,
        },
      ]}
      image={brand?.logo}
    >
      <Text className={style.description}>{model?.trim.description}</Text>
    </Page>
  );
};
