import { LineSearch } from "@autoprom/autoprom-icons";
import { Input, Menu, Pagination } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Page } from "../../components/page";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import style from "./style.module.css";

export const Leads = () => {
  const history = useHistory();
  const userToken = useStoreState("userToken");
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [leads, setLeads] = useState<
    Array<{
      _id: string;
      phoneNumber: string;
      fullName: string;
    }>
  >([]);

  const onChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    const fetchLeads = async () => {
      setLeads([]);
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/leads?query=${query}&page=${page}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setLeads(result.data);
      setTotalPages(result.meta.totalPages);
      setIsLoading(false);
    };

    fetchLeads();
  }, [userToken, query, page]);

  return (
    <Page
      title="Leads"
      header={
        <Page.Header backUrl="/" onAdd={() => history.push("/leads/add")} />
      }
    >
      <div className={style.search}>
        <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search by phone or name..."
          prefixIcon={LineSearch}
        ></Input>
      </div>

      <div className={style.items}>
        {isLoading && (
          <>
            <Menu.Loader />
            <Menu.Loader />
            <Menu.Loader />
            <Menu.Loader />
            <Menu.Loader />
          </>
        )}
        {!isLoading &&
          leads.map((lead) => {
            return (
              <Menu
                key={lead._id}
                description={lead.fullName ? lead.phoneNumber : undefined}
                onClick={() => {
                  history.push("/leads/" + lead._id);
                }}
              >
                {lead.fullName || lead.phoneNumber}
              </Menu>
            );
          })}
      </div>
      <div className={style.pagination}>
        {totalPages > 1 && (
          <Pagination
            isMinimal
            current={page}
            total={totalPages}
            onChange={onChangePage}
          />
        )}
      </div>
    </Page>
  );
};
