import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import style from "./index.module.css";
import { Button, Input } from "@autoprom/autoprom-ui";
import { useState } from "react";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";

export const AddContact = () => {
  const userToken = useStoreState("userToken");
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    year: "",
    exportedFrom: "",
    listingLink: "",
  });
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const history = useHistory();
  const { leadId } = useParams<{ leadId: string }>();

  const onPressSave = async () => {
    setIsSaving(true);

    const response = await fetch(
      getPath(`/admin/leads/${leadId}/send-whatsapp`),
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer " + userToken,
        },
      }
    );

    const result = await response.json();

    if (response.ok) {
      history.replace(`/leads/${leadId}/contact-history/${result.data.id}`);
      setIsSaving(false);
      return;
    }

    if (result.status === "error") {
      setErrors(result.data.errors);
    }

    setIsSaving(false);
  };

  const onChangeFormData =
    (key: keyof typeof formData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    };

  return (
    <Page
      title="Send Whatsapp"
      header={<Page.Header backUrl={`/leads/${leadId}`} />}
    >
      <div className={style.inputs}>
        <Input
          label="Brand"
          value={formData.brand}
          onChange={onChangeFormData("brand")}
          error={errors.brand?.[0]}
        />
        <Input
          label="Model"
          value={formData.model}
          error={errors.model?.[0]}
          onChange={onChangeFormData("model")}
        />
        <Input
          label="Year"
          value={formData.year}
          error={errors.year?.[0]}
          onChange={onChangeFormData("year")}
        />
        <Input
          label="Exported from"
          error={errors.exportedFrom?.[0]}
          value={formData.exportedFrom}
          onChange={onChangeFormData("exportedFrom")}
        />
        <Input
          label="Listing Link"
          error={errors.listingLink?.[0]}
          value={formData.listingLink}
          onChange={onChangeFormData("listingLink")}
        />
      </div>
      <div className={style.button}>
        <Button isLoading={isSaving} onClick={onPressSave}>
          Save
        </Button>
      </div>
    </Page>
  );
};
