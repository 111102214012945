import { Listings } from "../listings";

export const ListingsVerified: React.FC<{
  vehicleType: string;
}> = (props) => {
  const onPressListing = (props: { slug: string }) => {
    window.open(
      (process.env.REACT_APP_PORTAL || "https://autoprom.ae") +
        "/listing/" +
        props.slug
    );
  };

  return (
    <Listings
      listingType="verified"
      vehicleType={props.vehicleType}
      onPressListing={onPressListing}
    />
  );
};
