import { useHistory } from "react-router-dom";
import { Listings } from "../listings";

export const ListingsUnlisted: React.FC<{
  vehicleType: string;
}> = (props) => {
  const history = useHistory();
  const onPressListing = (props: { slug: string }) => {
    history.push("/listings/" + props.slug);
  };

  return (
    <Listings vehicleType={props.vehicleType} onPressListing={onPressListing} />
  );
};
