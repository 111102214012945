import { Tabs } from "@autoprom/autoprom-ui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { ListingsVerified } from "./components/listings-verified";
import { ListingsEdited } from "./components/listings-edited";
import { ListingsUnlisted } from "./components/listings-unlisted";
import style from "./style.module.css";

const listingTabs = [
  {
    value: "verified",
    label: "Verified",
  },
  {
    value: "unlisted",
    label: "Unlisted",
  },
  {
    value: "edited",
    label: "Edited",
  },
];

const listingsTabsMap: Record<
  string,
  React.FunctionComponent<{
    vehicleType: string;
  }>
> = {
  unlisted: ListingsUnlisted,
  edited: ListingsEdited,
  verified: ListingsVerified,
};

const toBreadcrumb = (vehicleType: string) => {
  return (
    {
      car: {
        label: "Car",
        value: "/car",
      },
      motorcycle: {
        label: "Motorcycle",
        value: "/motorcycle",
      },
    }[vehicleType] || {
      label: "Car",
      value: "/car",
    }
  );
};

export const Listings = () => {
  const [activeTab, setActiveTab] = useState(listingTabs[0].value);
  const { vehicleType } = useParams<{ vehicleType: string }>();
  const onChangeActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const ListingsComponent = listingsTabsMap[activeTab];

  return (
    <Page
      title="Listings"
      breadcrumbs={[toBreadcrumb(vehicleType)]}
      header={<Page.Header backUrl={"/" + vehicleType} />}
    >
      <div className={style["tabs-container"]}>
        <Tabs
          items={listingTabs}
          value={activeTab}
          onChange={onChangeActiveTab}
        />
      </div>
      <div className={style["listings"]}>
        <ListingsComponent vehicleType={vehicleType} />
      </div>
    </Page>
  );
};
