import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { PageLoader } from "../../components/page-loader";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import { Author } from "./components/author";
import { Details } from "./components/details";
import { Footer } from "./components/footer";
import { Gallery } from "./components/gallery";
import { Location } from "./components/location";
import { ListingContext } from "./context";
import style from "./style.module.css";

export const Listing: React.FunctionComponent<{
  isEdited?: boolean;
}> = (props) => {
  const [listing, setListing] = useState(null);
  const [settings, setSettings] = useState(null);
  const { slug } = useParams<{ slug: string }>();
  const authToken = useStoreState("userToken");
  useEffect(() => {
    const fetchListing = async () => {
      const [response, settings] = await Promise.all([
        fetch(
          getPath("/listings/" + (props.isEdited ? "edited/" : "") + slug),
          {
            headers: {
              "Content-Type": "application/json",
              "x-authorization": "Bearer " + authToken,
            },
          }
        ),
        fetch(getPath("/filters")).then((r) => r.json()),
      ]);

      const result = await response.json();

      setListing(
        props.isEdited
          ? {
              ...result.editedListing,
              brand: result.listing.brand,
              brandModel: result.listing.brandModel,
            }
          : result
      );
      setSettings(settings);
    };

    fetchListing();
  }, []);

  if (!listing || !settings) {
    return <PageLoader />;
  }

  return (
    <ListingContext.Provider
      value={{
        listing,
        settings,
      }}
    >
      <Page.Header backUrl={`/${(listing as any).vehicleType}/listings`} />
      <div className={style.container}>
        <Gallery />
        <Details />
        <Location />
        <Author />
        <Footer isEdited={props.isEdited} />
      </div>
    </ListingContext.Provider>
  );
};
