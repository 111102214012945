import { Button } from "@autoprom/autoprom-ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { getPath } from "../../../../services/api/getPath";
import { useStoreState } from "../../../../services/store";
import { ListingContext } from "../../context";
import { RejectModal } from "../reject-modal";
import style from "./style.module.css";
export const Footer: React.FunctionComponent<{
  isEdited?: boolean;
}> = (props) => {
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const context = useContext(ListingContext);
  const authToken = useStoreState("userToken");
  const history = useHistory();
  const onPressApprove = async () => {
    setIsApproving(true);
    try {
      await fetch(
        getPath(
          "/listings/" +
            (props.isEdited ? "edited/" : "") +
            context.listing?.id +
            "/approve"
        ),
        {
          method: "PATCH",
          headers: {
            "x-authorization": "Bearer " + authToken,
          },
        }
      );
      history.push(`/${context.listing?.vehicleType}/listings`);
    } catch {}
    setIsApproving(false);
  };

  const onPressReject = () => {
    setIsRejectModalVisible(true);
  };

  const onCloseRejectModal = () => setIsRejectModalVisible(false);

  return (
    <>
      <div className={style["footer"]}>
        <Button variant="ghost" onClick={onPressReject}>
          Reject
        </Button>
        <Button onClick={onPressApprove} isLoading={isApproving}>
          Approve
        </Button>
      </div>
      <RejectModal
        isEdited={props.isEdited}
        isVisible={isRejectModalVisible}
        onClose={onCloseRejectModal}
      />
    </>
  );
};
