import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../components/page";
import { PageLoader } from "../../components/page-loader";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import { Button, Text } from "@autoprom/autoprom-ui";
import style from "./style.module.css";
export const LeadContact = () => {
  const { leadId, contactId } = useParams<{
    leadId: string;
    contactId: string;
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [leadContact, setLeadContact] = useState({
    title: "",
    leadName: "",
    status: "",
    brand: "",
    model: "",
    exportedFrom: "",
    listingLink: "",
    message: "",
  });
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const fetchLeadContact = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/leads/${leadId}/lead-contacts/${contactId}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setLeadContact({
        title: result.data.contact.date,
        leadName: result.data.fullName,
        status: result.data.contact.status,
        brand: result.data.contact.brand,
        model: result.data.contact.model,
        exportedFrom: result.data.contact.exportedFrom,
        listingLink: result.data.contact.listingLink,
        message: result.data.contact.message,
      });
      // setLead(result.data);
      setIsLoading(false);
    };

    fetchLeadContact();
  }, [leadId, userToken, contactId]);

  const onClickResend = async () => {
    setIsResending(true);

    try {
      await fetch(
        getPath(`/admin/leads/${leadId}/lead-contacts/${contactId}/resend-sms`),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );
    } catch {}

    setIsResending(false);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      title={leadContact.title}
      header={<Page.Header backUrl={`/leads/${leadId}`} />}
      breadcrumbs={[
        {
          value: "/leads",
          label: "Leads",
        },
        {
          value: "/leads",
          label: leadContact.leadName,
        },
      ]}
    >
      <div className={style.info}>
        <Text variant="body3" color="black-50">
          Status
        </Text>
        <Text variant="body3">{leadContact.status}</Text>
        <Text variant="body3" color="black-50">
          Brand
        </Text>
        <Text variant="body3">{leadContact.brand}</Text>
        <Text variant="body3" color="black-50">
          Model
        </Text>
        <Text variant="body3">{leadContact.model}</Text>
        <Text variant="body3" color="black-50">
          Exported From
        </Text>
        <Text variant="body3">{leadContact.exportedFrom}</Text>
      </div>
      <div className={style["listing-link"]}>
        <Text variant="body3" color="black-50">
          Listing link
        </Text>
        <a href={leadContact.listingLink} target="_blank" rel="noreferrer">
          <Text variant="body3">{leadContact.listingLink}</Text>
        </a>
      </div>

      <div className={style.message}>
        <Text variant="title2">Message</Text>

        <Text variant="body3" color="black-50">
          <span
            dangerouslySetInnerHTML={{
              __html: leadContact?.message?.replace(/\n/g, `<br/>`),
            }}
          ></span>
        </Text>
      </div>
      <div className={style.resend}>
        <Button isLoading={isResending} onClick={onClickResend}>
          Resend SMS
        </Button>
      </div>
    </Page>
  );
};
