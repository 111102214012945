import { useContext } from "react";
import { ListingContext } from "../../context";
import { Avatar, Text } from "@autoprom/autoprom-ui";
import { getImagekitImages } from "../../../../utils/get-imagekit-images";
import style from "./style.module.css";

export const Author = () => {
  const context = useContext(ListingContext);

  if (!context.listing) {
    return null;
  }

  return (
    <>
      {!!context.listing.contacts && (
        <div className={style.container}>
          <Text variant="title2" className={style.subtitle}>
            Contacts
          </Text>

          <Text variant="label">Name</Text>
          <Text>{context.listing.contacts.name}</Text>
          <Text variant="label">Phone number</Text>
          <Text>{context.listing.contacts.mobileNumber}</Text>
          <Text variant="label">Email</Text>
          <Text>{context.listing.contacts.email}</Text>
        </div>
      )}

      {!!context.listing.author && (
        <div className={style.container}>
          <Text variant="title2" className={style.subtitle}>
            Author
          </Text>
          <div className={style.author}>
            {!!context.listing.author.avatar && (
              <Avatar
                content={getImagekitImages({
                  src: context.listing.author.avatar.original,
                })}
              ></Avatar>
            )}

            <div>
              <Text variant={"body1"}>
                {context.listing.author.firstName}{" "}
                {context.listing.author.lastName}
              </Text>
              <Text variant="body3" color={"black-50"}>
                {context.listing.author.email}
              </Text>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
