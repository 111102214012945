import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import style from "./index.module.css";
import { Menu } from "@autoprom/autoprom-ui";
import { LineArrowForward2 } from "@autoprom/autoprom-icons";
import { useEffect, useState } from "react";
import { useStoreState } from "../../services/store";
import { getPath } from "../../services/api/getPath";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../utils/getVehicleTypes";
import { PageLoader } from "../../components/page-loader";

const vehicleTypes = getVehicleTypes();

export const Trims = () => {
  const history = useHistory();
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
  }>();
  const [brand, setBrand] = useState<{
    logo: string;
    name: string;
    slug: string;
    vehicleType: string;
    models: Array<{
      value: string;
      label: string;
      trims: Array<{
        name: string;
        slug: string;
      }>;
    }>;
  }>();
  const [model, setModel] = useState<{
    value: string;
    label: string;
    trims: Array<{
      name: string;
      slug: string;
    }>;
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!brand) {
      return;
    }

    const foundModel = brand.models.find((m) => m.value === modelParam);

    setModel(foundModel);
  }, [brand, modelParam]);

  useEffect(() => {
    const fetchBrand = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/brands/${vehicleType}/${brandParam}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setBrand({
        name: result.data.name,
        slug: result.data.slug,
        vehicleType: result.data.vehicleType,
        logo: getImagekitImages({
          src: result.data.logo.original,
          width: "180",
        }),
        models: result.data.models.map((model: any) => ({
          value: model.slug,
          label: model.name,
          trims: model.trims,
        })),
      });

      setIsLoading(false);
    };

    fetchBrand();
  }, [brandParam, userToken, vehicleType]);

  const onAddTrim = () => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims/add-trim`
    );
  };

  const onPressTrim = (trimValue: string) => () => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims/${trimValue}`
    );
  };

  if (isLoading) {
    return <PageLoader />;
  }

  const trims = model?.trims;

  return (
    <Page
      header={
        <Page.Header
          onAdd={onAddTrim}
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}`}
        />
      }
      title={"Trims"}
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.name as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
      ]}
      image={brand?.logo}
    >
      <div className={style["list"]}>
        {trims?.map((item) => {
          return (
            <Menu
              suffixIcon={LineArrowForward2}
              onClick={onPressTrim(item.slug)}
            >
              {item.name}
            </Menu>
          );
        })}
      </div>
    </Page>
  );
};
