import { useHistory } from "react-router-dom";
import { Listings } from "../listings";

export const ListingsEdited: React.FC<{
  vehicleType: string;
}> = (props) => {
  const history = useHistory();
  const onPressListing = (props: { id: string }) => {
    history.push("/edited-listings/" + props.id);
  };

  return (
    <Listings
      vehicleType={props.vehicleType}
      listingType="edited"
      onPressListing={onPressListing}
    />
  );
};
