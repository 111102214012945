import { getPath } from "../../../../../services/api/getPath";

export const deleteTrim = async (
  props: { brand: string; model: string; vehicleType: string; trim: string },
  options: {
    userToken: string;
  }
) => {
  const response = await fetch(
    getPath(
      `/admin/brands/${props.vehicleType}/${props.brand}/models/${props.model}/trims/${props.trim}`
    ),
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "x-authorization": "Bearer " + options.userToken,
      },
    }
  );

  const result = await response.json();

  return result;
};
