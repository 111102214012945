import { YoutubePlayer } from "../../../../components/youtube-player";
import style from "./index.module.css";
import { Knob, Text } from "@autoprom/autoprom-ui";
import { LineEdit } from "@autoprom/autoprom-icons";

export type Review = {
  id: string;
  title: string;
  videoId: string;
  brand: {
    label: string;
    logo: string;
    value: string;
  };
  model: {
    label: string;
    value: string;
  };
};

export const VideoReview: React.FC<{
  review: Review;
  onPressEdit(reviewId: string): void;
}> = ({ review, onPressEdit }) => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.video}>
          <YoutubePlayer videoId={review.videoId}></YoutubePlayer>
        </div>
      </div>
      <div className={style.title}>
        <Text variant="body1">{review.title}</Text>
        <Knob
          icon={LineEdit}
          color="white"
          onClick={() => onPressEdit(review.id)}
        ></Knob>
      </div>
    </div>
  );
};
