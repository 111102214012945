import { Button, Input } from "@autoprom/autoprom-ui";
import { Page } from "../../components/page";
import style from "./index.module.css";
import { useState } from "react";
import { getPath } from "../../services/api/getPath";
import { useStoreState } from "../../services/store";
import { useHistory } from "react-router-dom";
export const AddLead = () => {
  const userToken = useStoreState("userToken");
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    source: "",
    phone: "",
  });
  const [errors, setErrors] = useState<Record<string, Array<string>>>({});
  const history = useHistory();

  const onPressSave = async () => {
    setIsSaving(true);

    const response = await fetch(getPath(`/admin/leads/`), {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "x-authorization": "Bearer " + userToken,
      },
    });

    const result = await response.json();

    if (response.ok) {
      history.replace("/leads/" + result.data.id);
      setIsSaving(false);
      return;
    }

    if (result.status === "error") {
      setErrors(result.data.errors);
    }

    setIsSaving(false);
  };

  const onChangeFormData =
    (key: keyof typeof formData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    };

  return (
    <Page title="Add lead" header={<Page.Header backUrl="/leads" />}>
      <div className={style.inputs}>
        <Input
          label="Lead name"
          value={formData.name}
          onChange={onChangeFormData("name")}
          error={errors.name?.[0]}
        />
        <Input
          label="Lead source"
          value={formData.source}
          error={errors.source?.[0]}
          onChange={onChangeFormData("source")}
        />
        <Input
          label="Phone number"
          type="tel"
          error={errors.phone?.[0]}
          value={formData.phone}
          onChange={onChangeFormData("phone")}
        />
      </div>

      <div className={style.button}>
        <Button isLoading={isSaving} onClick={onPressSave}>
          Save
        </Button>
      </div>
    </Page>
  );
};
