import { ImageSlider } from "@autoprom/autoprom-ui";
import { useContext } from "react";
import { getImagekitImages } from "../../../../utils/get-imagekit-images";
import { ListingContext } from "../../context";
import style from "./style.module.css";

export const Gallery = () => {
  const context = useContext(ListingContext);

  if (!context.listing) {
    return null;
  }

  return (
    <ImageSlider>
      {context.listing.images.map((item) => (
        <div className={style["image-container"]}>
          <img
            src={getImagekitImages({
              src: item.original,
              width: "750",
            })}
          />
        </div>
      ))}
    </ImageSlider>
  );
};
