import { Button, Input, InputPassword, Text } from "@autoprom/autoprom-ui";
import { useState } from "react";
import { getPath } from "../../services/api/getPath";
import { store } from "../../services/store";
import { defaultCredentials } from "../../utils/constants";
import style from "./style.module.css";

export const Login = () => {
  const [credentials, setCredentials] = useState(defaultCredentials);

  const onPressSignIn = async () => {
    const user = await fetch(getPath("/user/login/password"), {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((r) => r.json());

    // Only authorize admin user
    if (!user.user.roles.includes("admin")) {
      return;
    }

    store.set("userToken", user.token);
    store.set("user", user.user);
    localStorage.setItem("project-cars.admin.me", JSON.stringify(user));
  };

  const onChangeInput =
    (key: keyof typeof credentials) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCredentials((oldCredentials) => ({
        ...oldCredentials,
        [key]: e.target.value,
      }));
    };

  return (
    <>
      <div className={style["page"]}>
        <Text variant="title1">Sign in to continue</Text>

        <div className={style["sign-in-form"]}>
          <Input
            placeholder={"Email"}
            onChange={onChangeInput("email")}
            value={credentials.email}
          />
          <InputPassword
            placeholder={"Password"}
            onChange={onChangeInput("password")}
          />

          <Button onClick={onPressSignIn}>Sign in</Button>
        </div>
      </div>
    </>
  );
};
