import { Button, cx, Input } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../../../components/page";
import { PageLoader } from "../../../../components/page-loader";
import { YoutubePlayer } from "../../../../components/youtube-player";
import { getPath } from "../../../../services/api/getPath";
import { useStoreState } from "../../../../services/store";
import { getImagekitImages } from "../../../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../../../utils/getVehicleTypes";
import style from "./index.module.css";

const vehicleTypes = getVehicleTypes();

export const EditReview = () => {
  const authToken = useStoreState("userToken");
  const history = useHistory();
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
    review,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
    review: string;
  }>();
  const [model, setModel] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [brand, setBrand] = useState<{
    label: string;
    value: string;
    logo?: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [values, setValues] = useState<{
    title: string;
  }>({
    title: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const videoReviewResponse = await fetch(
        getPath(
          `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews/${review}`
        )
      );

      const videoReviewResult = await videoReviewResponse.json();

      setBrand(videoReviewResult.brand);
      setModel(videoReviewResult.model);
      setValues({
        title: videoReviewResult.title,
      });
      setIsLoading(false);
    };

    fetchData();
  }, [modelParam, brandParam, vehicleType, review]);

  const onChangeValues = (key: keyof typeof values) => (value: string) => {
    setValues((oldVals) => ({ ...oldVals, [key]: value }));
  };

  const onPressSave = async () => {
    setIsSaving(true);

    const result = await fetch(
      getPath(
        `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews/${review}`
      ),
      {
        body: JSON.stringify(values),
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer " + authToken,
        },
      }
    );

    if (result.status === 200) {
      history.push(
        `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
      );
    }
  };

  const onPressDelete = async () => {
    setIsDeleting(true);

    const result = await fetch(
      getPath(
        `/vehicle-types/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews/${review}`
      ),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-authorization": "Bearer " + authToken,
        },
      }
    );

    if (result.status === 200) {
      setIsDeleting(false);
      history.push(
        `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
      );
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      title="Edit video review"
      header={
        <Page.Header
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`}
        />
      }
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.label as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
        {
          label: "Video reviews",
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`,
        },
      ]}
    >
      <div className={style.input}>
        <Input
          label="Title"
          value={values.title || ""}
          onChange={(e) => onChangeValues("title")(e.target.value)}
        ></Input>
      </div>
      {!!values.title && (
        <div className={style["save-button"]}>
          <Button onClick={onPressSave} isFullWidth isLoading={isSaving}>
            Save
          </Button>
        </div>
      )}
      <div className={style["save-button"]}>
        <Button
          onClick={onPressDelete}
          isLoading={isDeleting}
          color="error"
          isFullWidth
          variant="ghost"
        >
          Delete Review
        </Button>
      </div>
    </Page>
  );
};
