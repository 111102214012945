import { Button, Input, InputTextarea } from "@autoprom/autoprom-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import validate from "validate.js";
import { Page } from "../../../../components/page";
import { PageLoader } from "../../../../components/page-loader";
import { getPath } from "../../../../services/api/getPath";
import { useStoreState } from "../../../../services/store";
import { getImagekitImages } from "../../../../utils/get-imagekit-images";
import { getVehicleTypes } from "../../../../utils/getVehicleTypes";
import style from "./index.module.css";
import { addTrim } from "./utils/addTrim";

const vehicleTypes = getVehicleTypes();

export const AddTrim = () => {
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    slug: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    slug: "",
  });
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
  }>();
  const [brand, setBrand] = useState<{
    logo: string;
    name: string;
    slug: string;
    vehicleType: string;
    models: Array<{
      value: string;
      label: string;
      trims: Array<{
        name: string;
        slug: string;
      }>;
    }>;
  }>();
  const [model, setModel] = useState<{
    value: string;
    label: string;
    trims: Array<{
      name: string;
      slug: string;
    }>;
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    if (!brand) {
      return;
    }

    const foundModel = brand.models.find((m) => m.value === modelParam);

    setModel(foundModel);
  }, [brand, modelParam]);
  useEffect(() => {
    const fetchBrand = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/brands/${vehicleType}/${brandParam}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setBrand({
        name: result.data.name,
        slug: result.data.slug,
        vehicleType: result.data.vehicleType,
        logo: getImagekitImages({
          src: result.data.logo.original,
          width: "180",
        }),
        models: result.data.models.map((model: any) => ({
          value: model.slug,
          label: model.name,
          trims: model.trims,
        })),
      });

      setIsLoading(false);
    };

    fetchBrand();
  }, [brandParam, userToken, vehicleType]);

  const onChangeInput =
    (input: keyof typeof formValues) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        [input]: e.target.value,
      }));
    };

  const onPressSave = async () => {
    const newValidationErrors = validate(formValues, {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      slug: {
        presence: {
          allowEmpty: false,
        },
      },
    });

    if (newValidationErrors) {
      setValidationErrors(() => {
        const errors = Object.keys(validationErrors).reduce(
          (prev, key) => ({
            ...prev,
            [key]: newValidationErrors[key]?.[0] || "",
          }),
          {} as typeof validationErrors
        );

        return errors;
      });

      return;
    }

    setIsSaving(true);

    try {
      const response = await addTrim(
        {
          ...formValues,
        },
        {
          brand: brand?.slug as string,
          model: model?.value as string,
          vehicleType,
          userToken,
        }
      );

      if (response.status === "error" && response.error.type === "generic") {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          name: response.error.message,
        }));

        setIsSaving(false);
        return;
      }

      history.goBack();
    } catch {}
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      title="Add new trim"
      header={
        <Page.Header
          backUrl={`/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`}
        />
      }
      breadcrumbs={[
        {
          label: vehicleTypes[vehicleType].label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands`,
        },
        {
          label: brand?.name as string,
          value: `/${vehicleType}/brands/${brandParam}`,
        },
        {
          label: model?.label as string,
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}`,
        },
        {
          label: "Trims",
          value: `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`,
        },
      ]}
    >
      <div className={style["input"]}>
        <Input
          label="Trim name"
          value={formValues.name}
          error={validationErrors.name}
          onChange={onChangeInput("name")}
        />
      </div>
      <div className={style["input"]}>
        <Input
          label="SEO slug"
          value={formValues.slug}
          error={validationErrors.slug}
          onChange={onChangeInput("slug")}
        />
      </div>
      <div className={style["input"]}>
        <InputTextarea
          onChange={onChangeInput("description")}
          label="SEO description"
          value={formValues.description}
          placeholder="Add description about this trim. It will be used to boost SEO search ability"
        />
      </div>
      <div className={style.button}>
        <Button onClick={onPressSave} isLoading={isSaving}>
          Save
        </Button>
      </div>
    </Page>
  );
};
