import ClearX, { paths } from "clearx";
import { useEffect, useState } from "react";

const initialState = {};

export const store = new ClearX(initialState, {
  devtools: true,
  name: "Project Cars",
});

export const useStoreState = (key: paths) => {
  const [data, unlink] = store.paths(key).link(useState());

  useEffect(() => () => unlink(), []);

  return data;
};
