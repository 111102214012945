import { Menu } from "@autoprom/autoprom-ui";
import { useHistory } from "react-router-dom";
import { Page } from "../../components/page";

export const Car = () => {
  const history = useHistory();
  return (
    <Page title="Car" header={<Page.Header backUrl="/" />}>
      <Menu onClick={() => history.push("/car/listings")}>Listings</Menu>
      <Menu onClick={() => history.push("/car/brands")}>Brands</Menu>
    </Page>
  );
};
