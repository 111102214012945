import { getPath } from "../../../../../services/api/getPath";

export const addTrim = async (
  props: {
    name: string;
    slug: string;
    description: string;
  },
  options: {
    vehicleType: string;
    userToken: string;
    brand: string;
    model: string;
  }
) => {
  const result = await fetch(
    getPath(
      `/admin/brands/${options.vehicleType}/${options.brand}/models/${options.model}/add-trim`
    ),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-authorization": "Bearer " + options.userToken,
      },
      body: JSON.stringify(props),
    }
  );

  const response = result.json();

  return response;
};
