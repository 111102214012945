import { findProperty } from "./find-property";

export const getDetailsAndEquipment = (listing: any, settings: any) => {
  const brand = listing.brand;
  const model = listing.brandModel;
  const color = findProperty(listing.color, settings.colors);
  let details = [
    {
      label: "Brand",
      value: brand.name,
    },
    {
      label: "Model",
      value: model?.name,
    },
    listing.trim && {
      label: "Trim",
      value: listing.trim?.name,
    },
    {
      label: "Year made",
      value: listing.year,
    },
    listing.mileage !== (null || undefined) && {
      label: "Mileage",
      value: listing.mileage.toLocaleString("en-US") + " km",
    },
    !!color && {
      label: "Color",
      value: color.label,
    },
  ];

  const equipment = [];
  const vehicleSettings = settings.byVehicleType[listing.vehicleType];

  if (listing.vehicleType === "car") {
    const interiorColor = findProperty(
      listing.car.interiorColor,
      vehicleSettings.interiorColor
    );
    const transmission = findProperty(
      listing.car.transmissionType,
      vehicleSettings.transmissionTypes
    );
    const bodyType = findProperty(
      listing.car.bodyType,
      vehicleSettings.bodyTypes
    );
    const engineType = findProperty(
      listing.car.engineType,
      vehicleSettings.engineTypes
    );
    const engineComposition = findProperty(
      listing.car.engineComposition,
      vehicleSettings.engineCompositions
    );
    const drivetrain = findProperty(
      listing.car.drivetrainType,
      vehicleSettings.drivetrainTypes
    );
    const seats = findProperty(listing.car.seats, vehicleSettings.seats);
    details = [
      ...details,
      !!interiorColor && {
        label: "Interior color",
        value: interiorColor.label,
      },
      !!bodyType && {
        label: "Body type",
        value: bodyType.label,
      },
      !!transmission && {
        label: "Transmission",
        value: transmission.label,
      },
      !!engineType && {
        label: "Engine",
        value: engineType.label,
      },
      !!engineComposition && {
        label: "Engine Composition",
        value: engineComposition.label,
      },
      !!listing.car.engineDisplacement && {
        label: "Displacement",
        value: listing.car.engineDisplacement + " L",
      },
      !!drivetrain && {
        label: "Drivetrain",
        value: drivetrain.label,
      },
      !!listing.car.horsePower && {
        label: "Horse power",
        value: listing.car.horsePower + "HP",
      },
      !!seats && {
        label: "Seats",
        value: seats.label,
      },
    ].filter((d) => !!d);

    const interiorMaterials = listing.car.interiorMaterials
      ?.map((item: any) => {
        return findProperty(item, vehicleSettings.interiorMaterials)?.label;
      })
      .join(", ");

    if (interiorMaterials) {
      equipment.push("Interior materials: " + interiorMaterials);
    }
    listing.car.multimedia?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.multimedia)?.label);
    });
    listing.car.comfort?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.comfort)?.label);
    });
    listing.car.other?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.other)?.label);
    });
  }

  if (listing.vehicleType === "motorcycle") {
    const category = findProperty(
      listing.motorcycle.category,
      vehicleSettings.categories
    );
    const engineComposition = findProperty(
      listing.motorcycle.engineComposition,
      vehicleSettings.engineCompositions
    );

    details = [
      ...details,
      {
        value: category?.label,
        label: "Category",
      },
      !!engineComposition && {
        label: "Engine Composition",
        value: engineComposition.label,
      },
      !!listing.motorcycle.engineDisplacement && {
        label: "Displacement",
        value: listing.motorcycle.engineDisplacement + " cc",
      },
      !!listing.motorcycle.horsePower && {
        label: "Horse power",
        value: listing.motorcycle.horsePower + "HP",
      },
    ].filter((d) => !!d);

    const suspension = listing.motorcycle.suspension
      ?.map((item: any) => {
        return findProperty(item, vehicleSettings.suspension)?.label;
      })
      .join(", ");

    if (suspension) {
      equipment.push("Suspension: " + suspension);
    }
    listing.motorcycle.safety?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.safety)?.label);
    });
    listing.motorcycle.comfort?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.comfort)?.label);
    });
    listing.motorcycle.multimedia?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.multimedia)?.label);
    });
    listing.motorcycle.other?.forEach((item: any) => {
      equipment.push(findProperty(item, vehicleSettings.other)?.label);
    });
  }

  return {
    details,
    equipment,
  };
};
