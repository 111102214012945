import { getPath } from "../../../../../services/api/getPath";

export const addModel = async (
  props: {
    name: string;
    description: string;
    slug: string;
  },
  options: {
    vehicleType: string;
    userToken: string;
    brand: string;
  }
) => {
  const result = await fetch(
    getPath(`/admin/brands/${options.vehicleType}/${options.brand}/add-model`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-authorization": "Bearer " + options.userToken,
      },
      body: JSON.stringify(props),
    }
  );

  const response = result.json();

  return response;
};
