import { getImagekitImages } from "../../../utils/get-imagekit-images";

export const toCardListing = (listing: any) => {
  const title = listing.texts.find((t: any) => t.key === "title");

  const specs = [String(listing.year)];

  if (listing.car) {
    specs.push(listing.car.bodyType);
    specs.push(listing.car.transmissionType);
  }

  if (listing.motorcycle) {
    specs.push(listing.motorcycle.category);
  }

  specs.push(
    new Intl.NumberFormat("en-US", {
      style: "unit",
      unit: "kilometer",
    }).format(listing.mileage)
  );

  return {
    id: listing._id,
    slug: listing.slug,
    title: title.value,
    description: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AED",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(listing.price),
    specs,
    picture: `${getImagekitImages({
      src: listing.images[0].original,
      width: "375",
    })} 1x, ${getImagekitImages({
      src: listing.images[0].original,
      width: "750",
    })} 2x`,
  };
};
