import { Menu } from "@autoprom/autoprom-ui";
import { useHistory } from "react-router-dom";
import { Page } from "../../components/page";

export const Motorcycle = () => {
  const history = useHistory();
  return (
    <Page title="Motorcycle" header={<Page.Header backUrl="/" />}>
      <Menu onClick={() => history.push("/motorcycle/listings")}>Listings</Menu>
      <Menu onClick={() => history.push("/motorcycle/brands")}>Brands</Menu>
    </Page>
  );
};
