import { useHistory, useParams } from "react-router-dom";
import { Page } from "../../components/page";
import style from "./index.module.css";
import { Menu } from "@autoprom/autoprom-ui";
import { LineArrowForward2 } from "@autoprom/autoprom-icons";
import { useEffect, useState } from "react";
import { getPath } from "../../services/api/getPath";
import { getImagekitImages } from "../../utils/get-imagekit-images";
import { PageLoader } from "../../components/page-loader";
import { useStoreState } from "../../services/store";
import { DeleteModal } from "./components/DeleteModal";

const vehicleTypes: Record<string, { value: string; label: string }> = {
  car: {
    value: "car",
    label: "Cars",
  },
  motorcycle: {
    value: "motorcycle",
    label: "Motorcycle",
  },
};

export const Model = () => {
  const {
    brand: brandParam,
    model: modelParam,
    vehicleType,
  } = useParams<{
    brand: string;
    model: string;
    vehicleType: string;
  }>();
  const [brand, setBrand] = useState<{
    logo: string;
    name: string;
    slug: string;
    vehicleType: string;
    models: Array<{
      value: string;
      label: string;
    }>;
  }>();
  const userToken = useStoreState("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const history = useHistory();
  const onEditModel = () => {
    history.push(
      `/${vehicleType}/brands/${brandParam}/models/${modelParam}/edit`
    );
  };

  const onDeleteModel = () => {
    setIsDeleteModalVisible(true);
  };

  useEffect(() => {
    if (!brand) {
      return;
    }

    const foundModel = brand.models.find((m) => m.value === modelParam);

    if (foundModel) {
      setModel(foundModel.label);
    }
  }, [brand, modelParam]);

  useEffect(() => {
    const fetchBrand = async () => {
      setIsLoading(true);
      const response = await fetch(
        getPath(`/admin/brands/${vehicleType}/${brandParam}`),
        {
          headers: {
            Accept: "application/json",
            "x-authorization": "Bearer " + userToken,
          },
        }
      );

      const result = await response.json();

      setBrand({
        name: result.data.name,
        slug: result.data.slug,
        vehicleType: result.data.vehicleType,
        logo: getImagekitImages({
          src: result.data.logo.original,
          width: "180",
        }),
        models: result.data.models.map((model: any) => ({
          value: model.slug,
          label: model.name,
        })),
      });

      setIsLoading(false);
    };

    fetchBrand();
  }, [brandParam, userToken, vehicleType]);

  if (isDeleteModalVisible) {
    return (
      <DeleteModal
        brand={brandParam}
        model={modelParam}
        vehicleType={vehicleType}
        onDeleted={() => {
          history.replace(`/${vehicleType}/brands/${brandParam}`);
        }}
        onClose={() => {
          setIsDeleteModalVisible(false);
        }}
      />
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      header={
        <Page.Header
          onEdit={onEditModel}
          onDelete={onDeleteModel}
          backUrl={`/${vehicleType}/brands/${brandParam}`}
        />
      }
      title={model}
      breadcrumbs={[
        {
          label: vehicleTypes[brand?.vehicleType as string]?.label,
          value: `/${vehicleType}`,
        },
        {
          label: "Brands",
          value: `/${vehicleType}/brands/`,
        },
        {
          label: brand?.name || "",
          value: `/${vehicleType}/brands/${brandParam}`,
        },
      ]}
      image={brand?.logo}
    >
      <div className={style["list"]}>
        <Menu
          suffixIcon={LineArrowForward2}
          onClick={() => {
            history.push(
              `/${vehicleType}/brands/${brandParam}/models/${modelParam}/trims`
            );
          }}
        >
          Trims
        </Menu>
        <Menu
          suffixIcon={LineArrowForward2}
          onClick={() => {
            history.push(
              `/${vehicleType}/brands/${brandParam}/models/${modelParam}/video-reviews`
            );
          }}
        >
          Video reviews
        </Menu>
      </div>
    </Page>
  );
};
